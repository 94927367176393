import React, { Component, Fragment } from "react";
import mockup from '../assets/images/mockup.png';
import {Helmet} from "react-helmet";

export default class Contact extends Component {
  render() {
    return (
      <Fragment>
        <Helmet>
          <meta charSet='utf-8' />
          <title>Contacto | Appgua</title>
        </Helmet>
        {/* <div className="container content"> */}

        <div className="row justify-content-center align-items-center">
                <div className="col-12 col-md-9 mt-3">
                  <div className="row justify-content-center align-items-center animated fadeInUp delay">
                    <div className="col-12 col-md-4 text-center text-md-left">
                      <h1 className="title">Contacto.</h1>
                      <div className="pl-md-4">
                        <h3 className="subtitle">Telefono:</h3>
                        <a className="contact-link" href="tel:+522211950516" target="blank"><i className="fa fa-phone mr-2"></i>+ 52 (221) 195 0516
                        </a><br></br>
                        <h3 className="subtitle">Correo:</h3>
                        <a className="contact-link" href="mailto:contacto@appgua.com.mx" target="blank"><i className="fa fa-envelope mr-2"></i>
                          contacto@appgua.com.mx</a>
                      </div>
                    </div>
                    <div className="col-8 col-md-4 mt-4">
                      <img className="mockup" src={mockup} alt='Appgua'/>
                    </div>
                  </div>
                </div>
            </div>
      </Fragment>
    )
  }
}
