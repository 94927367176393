import React from "react"
import ReactDOM from "react-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import "./styles/modal.scss"
//import PromotionCard from "./promotionCard"

export default function Modal(props) {
  if (!props.isOpen) {
    return null
  }

  return ReactDOM.createPortal(
    // NO DEBERIA SEGUIR IGUAL
    <div className='modal'>
      <div className='modalContainer'>
        <button onClick={props.onClose} className='btn btnClose'>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <div className='row'>
          <div className='col-12 col-md-6 no-pr'>
            <div className='body'>
              <h2 className='title'>{props.promotion.titulo}</h2>
              <div className='txt'>
                {props.promotion.descripcionCompleta
                  ? props.promotion.descripcionCompleta.map(paragrah => {
                      return <p>{paragrah}</p>
                    })
                  : null}
              </div>
              <div id='terminosYCondiciones'>
                <p className='conditions'>{props.promotion.condiciones}</p>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <img
              className='img-fluid'
              alt={props.promotion.titulo}
              src={props.promotion.imagen}
            />
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("modal")
  )
}
