import React, { Fragment } from "react";
// components
import Header from './header'
import Asistente from './asistente'
import Footer from './footer'

function Layout(props) {
  return (
    <Fragment>
      <Fragment>
        {/* <div className="appgua-bg bg-fixed-mobile d-none d-sm-block"></div> */}
        <Header />
        <div className='container content'>
          {props.children}
        </div>
        <div className=''>
          <Asistente />
        </div>
      </Fragment>
      <Footer/>
    </Fragment>
  )
}

export default Layout;
