import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import 'animate.css'
import './styles/footer.scss'


export default class Footer extends Component {
  render() {
    return (
      <Fragment>
        <footer className='footer-floating d-none d-sm-block animated fadeInUp delay-4s'>
          <ul className='legal'>
            <li className='list-inline-item'>
              <Link to='/terminos/'>Términos y condiciones</Link>
            </li>
            <li className='list-inline-item'>
              <Link to='/privacidad/'>Avisos de privacidad</Link>
            </li>
            <li className='list-inline-item'>
              <Link to='/preguntas/'>Preguntas frecuentes</Link>
            </li>
          </ul>
        </footer>
      </Fragment>
    )
  }
}
