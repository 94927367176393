import React, { Component, Fragment } from 'react'
import { Switch, Route } from "react-router-dom";
//screens
import Homescreen from "../screens/home";
import Tips from "../screens/tips";
import Promotions from "../screens/promotions";
import Contact from "../screens/contact";
import Terms from "../screens/terms";
import Privacy from "../screens/privacy";
import Notfound from "../screens/notfound";
import Preguntas from "../screens/preguntas";

//import ProtectedRoute from "./ProtectedRoute"
export default class AppNavigation extends Component {
  render() {
    return (
      <Fragment>
        <Switch>
          <Route path="/" exact component={Homescreen} />
          <Route path="/recomendaciones/" component={Tips} />
          <Route path="/promociones/" component={Promotions} />
          <Route path="/contacto/" component={Contact} />
          <Route path="/preguntas/" component={Preguntas} />
          <Route path="/terminos/" component={Terms} />
          <Route path="/privacidad/" component={Privacy} />
          <Route component={Notfound} />
        </Switch>
      </Fragment>
    )
  }
}
