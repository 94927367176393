import React, {Fragment} from 'react'
import { Link } from 'react-router-dom'
import { Helmet} from 'react-helmet'
import CallToAction from '../components/callToAction'
import mockup from '../assets/images/mockup.png'
import "./styles/404.scss"

export default function NotFound() {
  return (
    <Fragment>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Pagina no existe | Appgua </title>
      </Helmet>
      <div className='row align-items-center justify-content-center vh80 page404'>
        <div className='col-4'>
          <h2 className='title text-center'>
            Ups...parece que la pagina que buscas no existe :/
          </h2>
          <p className='text-center txt'>
            La página a la que intentas acceder es incorrecta o no existe,
            verifica la url o regresa al inicio.
          </p>
          <div className='btn text-center'>
            <Link to='/'>Volver a inicio</Link>
          </div>
        </div>
      </div>
      <CallToAction></CallToAction>
    </Fragment>
  )
}
