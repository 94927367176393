import React, { Fragment, useEffect, useState } from "react";
// import { Nav, NavLink } from 'reactstrap';
import '../screens/styles/preguntas.scss'
import {Helmet} from "react-helmet";
import ReactSpinner from 'react-bootstrap-spinner'

  export default function Preguntas(props) {
      const [questions, setQuestions] = useState([])
      const [loading, setLoading] = useState(true)
      const [error, setError] = useState(null)
      const url='https://appgua.com.mx/rest/'
      useEffect(() => {
        fetch(url+"preguntas")
          .then(res => res.json())
          .then(data => {
            setQuestions(data)
            setLoading(false)
            setError(null)
            console.log(data)
          })
          .catch(err => {
            console.log(err)
          })
      }, [1])

    let renderLoader = () => (
      <Fragment>
        <div className='row align-items-center justify-content-center vh80'>
          <ReactSpinner type='border' color='info' size='3' />
        </div>
      </Fragment>
    )

    let renderContent = () => (
      <div className='container terms-privacy'>
        <div className='row justify-content-center align-items-center'>
          <div className='col-12 col-md-8'>
            <h1 className='title'>Preguntas<br/>frecuentes</h1>
          </div>
          <div className='col-12 col-md-8'>
            {questions.map(question => {
              return (
                <Fragment>
                  <h3 className='subtitle'>{question.pregunta}</h3>
                  <p className='mb-4'>{question.respuesta}</p>
                </Fragment>
              )
            })}
          </div>
        </div>
      </div>
    )

    return (
      <Fragment>
        <Helmet>
          <meta charSet='utf-8' />
          <title>Preguntas frecuentes | Appgua </title>
          <meta name="description" content="¿Quieres saber cómo pedir tus garrafones de agua?.Consulta nuestra sección de preguntas frecuentes y empieza a pedir en Appgua."/>
            
        </Helmet>
        {loading ? renderLoader() : renderContent()}
      </Fragment>
    )
  }
