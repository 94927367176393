import React, { useState} from 'react'
import { NavLink, Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebookSquare,
  faInstagram
} from "@fortawesome/free-brands-svg-icons"
// assets
import logo from "../assets/images/Logo@2x.png"
import "./styles/header.scss"

export default function Header() {
  let [collapsed, setCollapsed] = useState(true)

  let toggleNavbar = () => {
    setCollapsed(!collapsed)
  }

  const classOne = collapsed
    ? "collapse navbar-collapse justify-content-end"
    : "collapse navbar-collapse show justify-content-end"
  const classTwo = collapsed
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right"

  return (
    <nav className='navbar navbar-expand-lg navbar-light gradient-white fixed-top'>
      <Link className='navbar-brand d-flex align-items-center' to='/'>
        <img className='logo' src={logo} alt='appgua' />
      </Link>
      <button
        onClick={toggleNavbar}
        className={`${classTwo}`}
        type='button'
        data-toggle='collapse'
        data-target='#navbarNav'
        aria-controls='navbarNav'
        aria-expanded='false'
        aria-label='Toggle navigation'>
        <span className='navbar-toggler-icon' />
      </button>
      <div className={`${classOne}`} id='navbarNav'>
        <ul className='navbar-nav'>
          <li className='nav-item'>
            <NavLink
              exact
              activeClassName='active'
              to='/recomendaciones/'
              onClick={toggleNavbar}>
              Recomendaciones
            </NavLink>
          </li>
          <li className='nav-item'>
            <NavLink
              activeClassName='active'
              to='/promociones/'
              onClick={toggleNavbar}>
              Promociones
            </NavLink>
          </li>
          <li className='nav-item'>
            <NavLink
              activeClassName='active'
              to='/contacto'
              onClick={toggleNavbar}>
              Contacto
            </NavLink>
          </li>
          <li className='nav-item'>
            <a href='https://www.facebook.com/AppguaMX/' className='sk-icon2'>
              <FontAwesomeIcon icon={faFacebookSquare} />
              {/* <i className="fab fa-facebook-f fa-.5x  sk-social-icon"></i> */}
            </a>
          </li>
          <li className='nav-item'>
            <a href='https://www.instagram.com/appgua' className='sk-icon4'>
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </li>
        </ul>
      </div>
    </nav>
  )
}
