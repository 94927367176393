import React, { Component, Fragment } from 'react'
import { Helmet } from 'react-helmet'
import 'animate.css'
import "./styles/asistente.scss";

let chatbotIcon = 'https://firebasestorage.googleapis.com/v0/b/appgua-usuario-dev.appspot.com/o/Resources%2Fchatbot-icon.png?alt=media&token=154b57d9-0a9e-4e0f-b152-9c85ae88589d'


export default class Asistente extends Component {

  onClickTrigger = () => {
    const trigger = document.getElementById('assisten')
    trigger.classList.toggle('show')
    let bagde = document.getElementById("badge")
    bagde.classList.toggle("hide")
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <script src="https://www.gstatic.com/dialogflow-console/fast/messenger/bootstrap.js?v=1"></script>
        </Helmet>
        <div className='floating animated fadeIn delay-2s'>
          <div id='badge' className='ordenaAqui animated  fadeInRight delay-3s'>
            Ordena aquí.<span className='blue-square'></span>
          </div>
          <df-messenger
            chat-icon={chatbotIcon}
            chat-title="Appgua Bot"
            agent-id="7ad12c80-790a-44b6-be6f-76fbb3b2d60f"
            language-code="es"
          ></df-messenger>
        </div>
      </Fragment>
    )
    }
  }
