import React, { Fragment } from "react";
import {Helmet} from "react-helmet";
// assets
import mockup from '../assets/images/mockup.png'
import appstore from '../assets/images/appstore.png'
import google from '../assets/images/google.png'
import 'animate.css'

export default function home() {
    return (
        <Fragment>

            <Helmet>
                <meta charSet='utf-8' />
                <title>Inicio | Appgua</title>
                <meta name="description" content="Solicita tus garrafones de agua, es fácil y accesible.La entrega es más rápida y segura.Prueba Appgua."/>
            </Helmet>

            <div className='row justify-content-center align-items-center vh80'>
                <div className='col-12 text-center d-block d-sm-block d-md-none animated fadeInUp delay'>
                    <div className="title">Lo nuevo de Appgua</div>
                    <div className="subtitle">La mejor forma de pedir agua</div>
                </div>

                {/* MOCKUP */}
                <div className='col-8 col-md-3 offset-md-2 animated fadeInUp delay'>
                    <img className='mockup' src={mockup} alt='Appgua' />
                </div>

                <div className='col-12 col-md-6 text-center text-md-left mt-3 animated fadeInUp delay'>
                    <div className='d-none d-sm-block'>
                        <h1 className='title'>Lo nuevo<br/>de Appgua</h1>
                    </div>
                    <div className='d-none d-sm-block'>
                        <h2 className='subtitle'>La mejor forma de pedir agua</h2>
                    </div>
                    <div className='d-block d-sm-block d-md-none'>
                        <h2 className='title-section'>Con AppGua<br/>Es fácil y rápido</h2>
                    </div>
                    <div className='d-block d-sm-block d-md-none description'>
                    <p>
                        Unete a los miles de usuarios que están usando AppGua en estos
                        momentos, descargarla de tu tienda de aplicaciones de confianza.
                    </p>
                    </div>

                    <div className='row justify-content-center justify-content-md-start stores'>
                    <div className='col-10 col-md-4 mb-2'>
                        <a href='https://appgua.com.mx/links/share/eNh4'>
                        <img
                            className='img-fluid'
                            src={appstore}
                            alt='Descarga IOS'
                        />
                        </a>
                    </div>
                    <div className='col-10 col-md-4 mb-2'>
                        <a href='https://appgua.com.mx/links/share/eNh4'>
                        <img
                            className='img-fluid'
                            src={google}
                            alt='Descarga Android'
                        />
                        </a>
                    </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
