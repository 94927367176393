import React, { Fragment, useState, useEffect } from "react"
import 'animate.css'
import "./styles/promotions.scss"

//components
import PromotionCard from "../components/promotionCard"
import Modal from "../components/modal"
import {Helmet} from "react-helmet";
import ReactSpinner from 'react-bootstrap-spinner'


export default function Promotions(props) {
  const [promotions, setPromotions] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalData, setModalData] = useState({})
  const url='https://appgua.com.mx/rest/'

  useEffect(() => {
    fetch(url+"promos")
      .then(res => res.json())
      .then(data => {
        // this.setState({ promotions: data, loading: false, error: null })
        setPromotions(data)
         console.log('data',data)
        setLoading(false)
        setError(null)
      })
      .catch(err => {
        console.log('error:', err)
      })
  }, [1])

  let handleModal = data => {
    setModalIsOpen(!modalIsOpen)
    setModalData(data)
    console.log('Data:', data)
  }

  let renderLoader = () => (
    <Fragment>
      <div className='row align-items-center justify-content-center vh80'>
        <ReactSpinner type='border' color='info' size='3' />
      </div>
    </Fragment>
  )

  let renderPromotions = () => (
    <Fragment>
      <section id='promotions'>
        <div className='header'>
          <h2 className='title'>Promociones</h2>
        </div>
        <div className='row'>
          {promotions.map(promotion => (
            <PromotionCard
              key={promotion.id}
              promotion={promotion}
              openModal={() => handleModal(promotion)}
            />
          ))}
        </div>
      </section>
      <Modal
        isOpen={modalIsOpen}
        onClose={handleModal}
        promotion={modalData}
      />
    </Fragment>
  )

  return (
    <Fragment>
      <Helmet>
        <meta charSet='utf-8' />
        <meta name="description" content="Aprovecha nuestras promociones y disfruta de Appgua."/>
        <title>Promociones | Appgua</title>
      </Helmet>
      {loading === true
        ? renderLoader()
        : renderPromotions()}
    </Fragment>
  )
}
