import React, { Fragment } from "react"
import 'animate.css'
import "./styles/tipCard.scss"

export default function PromotionCard(props) {
  return (
    <Fragment>
      <div className='col-12 col-md-4 col-lg-3 mb-4'>
        <div
          onClick={props.openModal}
          role='button'
          className='card animated fadeInUp'>
          <img
            className='img-fluid'
            alt={props.promotion.titulo}
            src={props.promotion.imagen}
          />
        </div>
      </div>
    </Fragment>
  )
}
