import React, { Fragment, useState, useEffect } from 'react';
import {Helmet} from 'react-helmet'
import ReactSpinner from 'react-bootstrap-spinner'
import './styles/terms.scss'

export default function Privacy(props) {
  const [terms, setTerms] = useState([])
  const [loading, setLoading] = useState(true)
  const url='https://appgua.com.mx/rest/'
  useEffect(() => {
    fetch(url+"aviso")
      .then(res => res.json())
      .then(data => {
        setTerms(data)
        setLoading(false)
        console.log(data)
      })
      .catch(err => {
        console.log(err)
      })
  }, [1])

  let renderLoader = () => (
    <Fragment>
      <div className='row align-items-center justify-content-center vh80'>
        <ReactSpinner type='border' color='info' size='3' />
      </div>
    </Fragment>
  )

  let renderContent = () => (
    <div className='container terms-privacy'>
      <div className='row justify-content-center align-items-center'>
        <div className='col-12 col-md-8'>
          <h1 className='title'>Aviso de privacidad</h1>
        </div>
        <div className='col-12 col-md-8'>
          {terms.map(term => {
            return (
              <Fragment>
                <h3 className='subtitle'>{term.titulo}</h3>
                <p>{term.contenido}</p>
              </Fragment>
            )
          })}
        </div>
      </div>
    </div>
  )

  return (
    <Fragment>
      <Helmet>
        <meta charSet='utf-8' />
        <meta name="robots" content="noindex" />
        <title>Aviso de privacidad | Appgua </title>
      </Helmet>
      {loading ? renderLoader() : renderContent()}
    </Fragment>
  )
}
