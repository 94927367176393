import React, { Fragment, useState, useEffect } from "react"
import "./styles/tips.scss"
import {Helmet} from "react-helmet";
// components
import TipCard from "../components/tip-card"
import ReactSpinner from 'react-bootstrap-spinner'

export default function Tips() {
  const [loading, setLoading] = useState(true)
  const [tips, setTips] = useState([])
  const url='https://appgua.com.mx/rest/'
  useEffect(() => {
    fetch(url+"recomendaciones")
      .then(res => res.json())
      .then(data => {
        setTips(data)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
      })
  }, [1])

  let renderLoader = () => (
    <Fragment>
      <div className='row align-items-center justify-content-center vh80'>
        <ReactSpinner type='border' color='info' size='3' />
      </div>
    </Fragment>
  )

  let renderTips = () => (
    <Fragment>
      <section id='tips'>
        <div className='header'>
          <h2 className='Recomendaciones'>Recomendaciones</h2>
        </div>
        <div className='row'>
          {tips.map(tip => (
            <TipCard key={tip.id} recommendation={tip} />
          ))}
        </div>
      </section>
    </Fragment>
  )

  return (
    <Fragment>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Recomendaciones | Appgua</title>
      </Helmet>
      {loading === true ? renderLoader() : renderTips()}
    </Fragment>
  )
}

