import React from "react"
import "./styles/tipCard.scss"

export default function TipCard(props) {
  return (
    <div className='col-12 col-md-3 mb-4 d-flex align-items-stretch'>
      <div className='card '>
        <div className='px-4 py-4'>
          <img
            className='img-fluid'
            alt='promo'
            src={props.recommendation.image}
          />
        </div>
        <div className='body'>
          <h2 className='txt'>{props.recommendation.caption}</h2>
          {/* <p className="txt">{props.recommendation.description}</p> */}
        </div>
      </div>
    </div>
  )
}
