import React from "react";
import { BrowserRouter as Router } from "react-router-dom"

import "bootstrap/dist/css/bootstrap.css"
import "./assets/styles/global.scss"

//components
import Layout from "./components/layout";
import AppNavigation from "./screens/app-navigation";

function App() {
  return (
    <Router>
      <Layout>
        <AppNavigation />
      </Layout>
    </Router>
  );
}

export default App;
