import React from 'react'

import './styles/callToAction.scss'
import ios from '../assets/images/appstore.png'
import Android from '../assets/images/google.png'

export default function CallToAction() {
    return (
        <div className='cta-container'>
            <div className='row justify-content-center align-items-center'>
            <div className="col-12">
                <h2 className='title text-center'>Con Appgua es facil y rapido.¡Descargala Ahora!</h2>
            </div>
            <div className="col-11 col-md-2">
                <a href="https://itunes.apple.com/mx/app/appgua/id1451893878?mt=8">
                    <img src={ios} className="img-fluid" alt="IOS"/>
                </a>
            </div>
            <div className="col-11 col-md-2">
                <a href="https://play.google.com/store/apps/details?id=mx.smartkode.appagua&hl=es_MX">
                    <img src={Android} className="img-fluid" alt="Android"/>
                </a>
            </div>
        </div>
        </div>
    )
}
